// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/styles.css';

// import required modules
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';

// import img

import imagen1 from '../assets/foto1.jpeg';
import imagen2 from '../assets/foto2.jpeg';
import imagen3 from '../assets/foto3.jpeg';
import imagen4 from '../assets/foto4.jpeg';
import imagen5 from '../assets/foto5.jpeg';
import imagen6 from '../assets/foto6.jpeg';
import imagen7 from '../assets/foto7.jpeg';


export default function Carrusel() {
  return (
    <>
      <Swiper
        effect={'flip'}
        grabCursor={true}
        pagination={false}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={imagen1} alt='imagen1'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagen2} alt='imagen2'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagen3} alt='imagen3'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagen4} alt='imagen4'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagen5} alt='imagen5'/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagen6} alt='imagen6'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src={imagen7} alt='imagen7'/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

