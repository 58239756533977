import React from 'react';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#f0f0f0' }}>
      <Typography variant="body2" color="textSecondary">
        &copy; {currentYear} Camping Terminal Lasoseta | Todos los derechos reservados  
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Desarrollado por AleDev 
      </Typography>
    </footer>
  );
};

export default Footer;
