import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import IconButton from '@mui/material/IconButton';



//img

const theme = createTheme();
theme.typography.h1 = { 
            fontSize: '4rem', 
            '@media (max-width:600px)': {
                fontSize: '2rem',
              },
            fontFamily: 'Whisper', 
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: '#E9B690',
            textShadow: '2px 2px 4px #817052',
            whiteSpace: 'nowrap',
            paddingTop: 50
            }
    

export default function Titulo(){
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ 
            width: 'auto', 
            margin: 'auto', 
            textAlign: 'center'}}>
            <Typography variant='h1' gutterBottom >Camping Terminal Lasoseta</Typography>
             {/* Añadir íconos con enlaces */}
        <Box sx={{display: 'flex', grap: 4, justifyContent: 'center'}}>
        <IconButton
                aria-label="Llamar"
                onClick={() => {
                    // Lógica para redirigir a la llamada telefónica
                    window.location.href = 'tel:+541128832092'; // Reemplaza con tu número de teléfono
                }}
                >
                <CallIcon sx={{ fontSize: 40 }} color="primary"/>
                1128832092
                </IconButton>
                <IconButton
                className='icono-verde'
                aria-label="WhatsApp"
                onClick={() => {
                    // Lógica para redirigir a WhatsApp
                    window.open('https://wa.me/+541128832092', '_blank'); // Reemplaza con tu número de teléfono de WhatsApp
                }}
                >
                <WhatsAppIcon sx={{ fontSize: 40 }} />
                1128832092
                </IconButton>

        </Box>

            </Box>
        </ThemeProvider>
    );
}