import React from 'react';
import NavBar from './components/NavBar';
import Carrusel from './components/Carrusel';
import Footer from './components/Footer';
import Informacion from './components/Informacion';
import Titulo from './components/Titulo';

const App = () => {
  return (
    <div>
      <NavBar />
      <Titulo/>
      <Carrusel/>
      <Informacion />
     <Footer/>

    </div>
  );
};

export default App;
