import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WifiIcon from '@mui/icons-material/Wifi';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

function InfoBlock({ icon, text }) {

    let separator = <br/>;
    let IconComponent;

    if (icon === 'wifi') {
      IconComponent = WifiIcon;
    } else if (icon === 'tienda') {
      IconComponent = HolidayVillageIcon;
    } else if (icon === 'bus') {
      IconComponent = DirectionsBusIcon; 
    } else {
      IconComponent = null;
    }
    
    return (
    <Paper elevation={3} style={{ padding: 50, marginTop: '15px', fontFamily: 'monospace'}}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Avatar><IconComponent /></Avatar>
        </Grid>
        <Grid item>
          <Typography variant="article" style={{marginTop: '30px', fontFamily: 'monospace'}}> {IconComponent && separator}{text}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

function Informacion() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} >
        <InfoBlock icon="tienda" text="Hemos creado un espacio acogedor para que disfrutes de la tranquilidad y la conveniencia en cada momento de tu estadía. Un refugio en San Clemente del Tuyú, donde la naturaleza se encuentra con la comodidad a un precio inmejorable." />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InfoBlock icon="wifi" text="Nuestro camping cuenta con wifi, baños y duchas con agua caliente. Áreas con sombra y parrillas para su uso. Comercios linderos y un ambiente familiar para que puedar vacacionar en la hermosa costa altántica de San Clemente del Tuyú." />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InfoBlock icon="bus" text="Ubicado estratégicamente, con acceso directo desde la ruta y muy cerquita de la playa, nuestro camping te ofrece la escapada perfecta. A metros de la terminal de omnibus de San Clememnte del Tuyú." />
      </Grid>
    </Grid>
  );
}

export default Informacion;
